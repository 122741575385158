import React from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Box, Typography } from '@mui/material';

const DateRangePickerComponent = ({ startDate, endDate, onChange }) => {
  const handleSelect = (ranges) => {
    onChange({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate
    });
  };

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Typography variant="subtitle1" gutterBottom>Date Range</Typography>
      <DateRangePicker
        ranges={[
          {
            startDate: startDate,
            endDate: endDate,
            key: 'selection',
          }
        ]}
        onChange={handleSelect}
      />
    </Box>
  );
};

export default DateRangePickerComponent;