export const fetchJobData = async (startDate, endDate) => {
    const start_days_ago = calculateDaysAgo(startDate);
    const end_days_ago = calculateDaysAgo(endDate);
  
    const response = await fetch(`/api/v1/job_aggregations?start_days_ago=${start_days_ago}&end_days_ago=${end_days_ago}`);
    const data = await response.json();
    return data.data;
  };
  
  export const fetchOtherData = async (params) => {
    const response = await fetch(`/api/v1/other_data?${new URLSearchParams(params)}`);
    const data = await response.json();
    return data;
  };
  
  const calculateDaysAgo = (date) => {
    const today = new Date();
    const timeDiff = today.getTime() - date.getTime();
    return Math.ceil(timeDiff / (1000 * 3600 * 24));
  };