
import React from 'react';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './styles/theme';
import Dashboard from './components/Dashboard/Dashboard';
import logo from './logo.svg';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <div className="App">
          <Dashboard />
        </div>
    </ThemeProvider>
  );
}

export default App;
