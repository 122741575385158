// 
import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import JobAggregationTab from './tabs/JobAggregations/JobAggregationTab';
import ByVesselTab from './tabs/ByVesselAggregations/ByVesselTab';
import ByDateTab from './tabs/ByDateAggregations/ByDateTab';
import ByPortTab from './tabs/ByPortAggregations/ByPortTab';
import ByShipTab from './tabs/ByShipAggregations/ByShipTab';
import { DashboardProvider } from '../../contexts/DashboardContext';


const Dashboard = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <DashboardProvider>
      <Tabs selectedIndex={activeTab} onSelect={index => setActiveTab(index)}>
        <TabList>
          <Tab>Job Aggregation</Tab>
          <Tab>By Vessel</Tab>
          <Tab>By Date</Tab>
          <Tab>By Port</Tab>
          <Tab>By Ship</Tab>
        </TabList>

        <TabPanel>
          <JobAggregationTab />
        </TabPanel>
        <TabPanel>
          <ByVesselTab />
        </TabPanel>
        <TabPanel>
          <ByDateTab />
        </TabPanel>
        <TabPanel>
          <ByPortTab />
        </TabPanel>
        <TabPanel>
          <ByShipTab />
        </TabPanel>
      </Tabs>
    </DashboardProvider>
  );
};

export default Dashboard;