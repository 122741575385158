import React, { useState, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line
} from 'recharts';
import {
  Paper, Typography, Box, ToggleButtonGroup, ToggleButton, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Paper elevation={3} sx={{ p: 1 }}>
        <Typography variant="body2">{`${label}: ${payload[0].value.toFixed(2)}`}</Typography>
      </Paper>
    );
  }
  return null;
};

const ChartComponent = ({ data }) => {
  const theme = useTheme();
  const [metricType, setMetricType] = useState('total_activities');
  const [chartType, setChartType] = useState('bar');
  const [sortBy, setSortBy] = useState('alphabetical');

  const processedData = useMemo(() => {
    let sortedData = [...data];
    if (sortBy === 'value') {
      sortedData.sort((a, b) => b[metricType] - a[metricType]);
    } else {
      sortedData.sort((a, b) => a.vessel_name.localeCompare(b.vessel_name));
    }
    return sortedData;
  }, [data, metricType, sortBy]);

  const handleMetricTypeChange = (event, newMetricType) => {
    if (newMetricType !== null) {
      setMetricType(newMetricType);
    }
  };

  const handleChartTypeChange = (event, newChartType) => {
    if (newChartType !== null) {
      setChartType(newChartType);
    }
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  const getYAxisLabel = (metricType) => {
    switch (metricType) {
      case 'total_activities':
        return 'Number of Activities';
      case 'total_ships_assisted':
        return 'Number of Ships';
      case 'total_fuel_burned':
        return 'Fuel Burned (liters)';
      case 'total_time_spent':
        return 'Time Spent (hours)';
      default:
        return '';
    }
  };

  const renderChart = () => {
    const ChartComponent = chartType === 'bar' ? BarChart : LineChart;
    const DataComponent = chartType === 'bar' ? Bar : Line;

    return (
      <ResponsiveContainer width="100%" height="80%">
        <ChartComponent data={processedData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="vessel_name" />
          <YAxis label={{ value: getYAxisLabel(metricType), angle: -90, position: 'insideLeft' }} />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <DataComponent
            type="monotone"
            dataKey={metricType}
            fill={theme.palette.primary.main}
            stroke={theme.palette.primary.main}
            animationBegin={0}
            animationDuration={1500}
          />
        </ChartComponent>
      </ResponsiveContainer>
    );
  };

  return (
    <Box sx={{ width: '100%', height: 500, p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Job Aggregation Chart
      </Typography>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
        <ToggleButtonGroup
          value={metricType}
          exclusive
          onChange={handleMetricTypeChange}
          aria-label="metric type"
        >
          <ToggleButton value="total_activities" aria-label="total activities">
            Total Activities
          </ToggleButton>
          <ToggleButton value="total_ships_assisted" aria-label="ships assisted">
            Ships Assisted
          </ToggleButton>
          <ToggleButton value="total_fuel_burned" aria-label="fuel burned">
            Fuel Burned
          </ToggleButton>
          <ToggleButton value="total_time_spent" aria-label="time spent">
            Time Spent
          </ToggleButton>
        </ToggleButtonGroup>
        <ToggleButtonGroup
          value={chartType}
          exclusive
          onChange={handleChartTypeChange}
          aria-label="chart type"
        >
          <ToggleButton value="bar" aria-label="bar chart">
            Bar
          </ToggleButton>
          <ToggleButton value="line" aria-label="line chart">
            Line
          </ToggleButton>
        </ToggleButtonGroup>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="sort-select-label">Sort By</InputLabel>
          <Select
            labelId="sort-select-label"
            value={sortBy}
            label="Sort By"
            onChange={handleSortChange}
          >
            <MenuItem value="alphabetical">Alphabetical</MenuItem>
            <MenuItem value="value">Value</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {renderChart()}
    </Box>
  );
};

export default ChartComponent;