// /app_api/client/src/contexts/DashboardContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { fetchJobData, fetchOtherData } from '../api/dataFetchers';

const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
  const [sharedState, setSharedState] = useState({
    jobData: [],
    otherData: [],
    // You can add more datasets here
  });
  const [dateRange, setDateRange] = useState({
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    endDate: new Date(Date.now() - 24 * 60 * 60 * 1000)
  });
  const [loading, setLoading] = useState({
    jobData: true,
    otherData: true,
    // Add loading states for other datasets
  });

  useEffect(() => {
    const loadData = async () => {
      setLoading(prev => ({ ...prev, jobData: true }));
      const jobData = await fetchJobData(dateRange.startDate, dateRange.endDate);
      setSharedState(prev => ({ ...prev, jobData }));
      setLoading(prev => ({ ...prev, jobData: false }));
    };
    loadData();
  }, [dateRange]);

  const updateDateRange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const fetchDataSet = async (dataSetName, params = {}) => {
    setLoading(prev => ({ ...prev, [dataSetName]: true }));
    let newData;
    switch(dataSetName) {
      case 'otherData':
        newData = await fetchOtherData(params);
        break;
      // Add cases for other datasets
      default:
        console.error(`Unknown dataset: ${dataSetName}`);
        return;
    }
    setSharedState(prev => ({ ...prev, [dataSetName]: newData }));
    setLoading(prev => ({ ...prev, [dataSetName]: false }));
  };

  const updateSharedState = (key, value) => {
    setSharedState(prev => ({ ...prev, [key]: value }));
  };

  return (
    <DashboardContext.Provider 
      value={{ 
        sharedState, 
        dateRange, 
        updateDateRange, 
        loading, 
        fetchDataSet,
        updateSharedState
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => useContext(DashboardContext);
