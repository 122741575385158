import React from 'react';
import { Box, Typography } from '@mui/material';
import ByVesselChart from './ByDateChart';
import { StyledBox } from './ByDateStyles';

const ByVesselTab = () => {
  return (
    <StyledBox>
      <Typography variant="h4" gutterBottom>
        By Vessel Dashboard
      </Typography>
      <ByVesselChart />
      {/* Add other components or filters here */}
    </StyledBox>
  );
};

export default ByVesselTab;