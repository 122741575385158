// /app_api/client/src/styles/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    // Add more color definitions if needed
  },
  typography: {
    // Customize typography
  },
  // Add other theme customizations
});

export default theme;