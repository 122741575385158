// Placeholder chart
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { 
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer 
} from 'recharts';
import { 
  Paper, Typography, Box, ToggleButtonGroup, ToggleButton 
} from '@mui/material';

const generateData = (dataKey) => {
  const categories = ['Category A', 'Category B', 'Category C', 'Category D', 'Category E'];
  return categories.map(category => ({
    name: category,
    [dataKey]: Math.floor(Math.random() * 1000)
  }));
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Paper elevation={3} sx={{ p: 1 }}>
        <Typography variant="body2">{`${label} : ${payload[0].value}`}</Typography>
      </Paper>
    );
  }
  return null;
};

const ByVesselChart = () => {
  const theme = useTheme();
  const [dataKey, setDataKey] = useState('value1');
  const [data, setData] = useState(() => generateData(dataKey));

  const handleDataKeyChange = (event, newDataKey) => {
    if (newDataKey !== null) {
      setDataKey(newDataKey);
      setData(generateData(newDataKey));
    }
  };

  const regenerateData = () => {
    setData(generateData(dataKey));
  };

  return (
    <Box sx={{ width: '100%', height: 500, p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Interactive Placeholder Chart
      </Typography>
      <Box sx={{ mb: 2 }}>
        <ToggleButtonGroup
          value={dataKey}
          exclusive
          onChange={handleDataKeyChange}
          aria-label="data key"
        >
          <ToggleButton value="value1" aria-label="value 1">
            Value 1
          </ToggleButton>
          <ToggleButton value="value2" aria-label="value 2">
            Value 2
          </ToggleButton>
        </ToggleButtonGroup>
        <ToggleButton value="regenerate" onClick={regenerateData} sx={{ ml: 2 }}>
          Regenerate Data
        </ToggleButton>
      </Box>
      <ResponsiveContainer width="100%" height="80%">
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar 
            dataKey={dataKey} 
            fill={theme.palette.primary.main}
            animationBegin={0}
            animationDuration={1500}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

// ByVesselChart is a placeholder interactive chart
export default ByVesselChart;
