import React from 'react';
import { 
  Drawer, 
  Button, 
  Box, 
  Typography, 
  FormGroup, 
  FormControlLabel, 
  Checkbox
} from '@mui/material';
import { styled } from '@mui/material/styles';

import DateRangePickerComponent from './DateRangePicker';

const FilterBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '800px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const ContentArea = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

const ApplyButtonContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const FiltersDrawer = ({ 
  open, 
  onClose, 
  filters, 
  setFilters, 
  dateRange, 
  updateDateRange, 
  categories,
  applyFilters
}) => {
  const handleFilterChange = (category, item) => {
    setFilters(category, item);
  };

  const handleDateRangeChange = (newDateRange) => {
    updateDateRange(newDateRange);
  };

  const handleApplyFilters = () => {
    applyFilters();
    onClose();
  };

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: '800px' }
      }}
    >
      <FilterBox>
        <Typography variant="h6" gutterBottom>Filters</Typography>
        <ContentArea>
          <DateRangePickerComponent 
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            onChange={handleDateRangeChange}
          />
          {Object.entries(categories).map(([category, items]) => (
            <Box key={category} sx={{ mt: 2 }}>
              <Typography variant="subtitle1">{category}</Typography>
              <FormGroup>
                {items.map(item => (
                  <FormControlLabel
                    key={item}
                    control={
                      <Checkbox
                        checked={filters[category]?.[item] || false}
                        onChange={() => handleFilterChange(category, item)}
                      />
                    }
                    label={item}
                  />
                ))}
              </FormGroup>
            </Box>
          ))}
        </ContentArea>
        <ApplyButtonContainer>
          <Button variant="contained" onClick={handleApplyFilters} fullWidth>
            Apply Filters
          </Button>
        </ApplyButtonContainer>
      </FilterBox>
    </Drawer>
  );
};

export default FiltersDrawer;