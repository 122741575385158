import React, { useState, useEffect, useMemo } from 'react';
import { useDashboard } from '../../../../contexts/DashboardContext';
import { Button, Box, Typography } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { styled } from '@mui/material/styles';

import FiltersDrawer from '../../shared/FiltersDrawer';
import ChartComponent from './JobAggregationChart';

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
}));

const JobAggregationTab = () => {
  const { sharedState, dateRange, updateDateRange, loading, fetchJobData } = useDashboard();
  const [filteredData, setFilteredData] = useState([]);
  const [localFilters, setLocalFilters] = useState({
    vessels: {},
    tripTypes: {},
    jobPortCodes: {},
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [localDateRange, setLocalDateRange] = useState(dateRange);

  const categories = useMemo(() => ({
    vessels: [...new Set(sharedState.jobData.map(item => item.vessel_name))],
    tripTypes: [...new Set(sharedState.jobData.map(item => item.trip_type))],
    jobPortCodes: [...new Set(sharedState.jobData.map(item => item.job_port_code))],
  }), [sharedState.jobData]);

  useEffect(() => {
    if (!loading.jobData) {
      const newFilteredData = sharedState.jobData.filter(item => 
        (Object.values(localFilters.vessels).every(v => !v) || localFilters.vessels[item.vessel_name]) &&
        (Object.values(localFilters.tripTypes).every(v => !v) || localFilters.tripTypes[item.trip_type]) &&
        (Object.values(localFilters.jobPortCodes).every(v => !v) || localFilters.jobPortCodes[item.job_port_code])
      );
      
      // Aggregate data by vessel
      const aggregatedData = newFilteredData.reduce((acc, item) => {
        if (!acc[item.vessel_name]) {
          acc[item.vessel_name] = {
            vessel_name: item.vessel_name,
            total_activities: 0,
            total_ships_assisted: 0,
            total_fuel_burned: 0,
            total_time_spent: 0,
          };
        }
        acc[item.vessel_name].total_activities++;
        acc[item.vessel_name].total_ships_assisted++;
        acc[item.vessel_name].total_fuel_burned += item.total_fuel_burned_calc;
        acc[item.vessel_name].total_time_spent += item.total_time_spent_s / 3600; // Convert seconds to hours
        return acc;
      }, {});

      setFilteredData(Object.values(aggregatedData));
    }
  }, [sharedState.jobData, localFilters, loading.jobData]);

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  const applyFilters = async () => {
    if (localDateRange.startDate !== dateRange.startDate || localDateRange.endDate !== dateRange.endDate) {
      updateDateRange(localDateRange);
      await fetchJobData(localDateRange.startDate, localDateRange.endDate);
    }
    setIsDrawerOpen(false);
  };

  const handleDateRangeChange = (newDateRange) => {
    setLocalDateRange(newDateRange);
  };

  const handleFilterChange = (category, item) => {
    setLocalFilters(prev => ({
      ...prev,
      [category]: {
        ...prev[category],
        [item]: !prev[category][item]
      }
    }));
  };

  if (loading.jobData) {
    return <div>Loading job data...</div>;
  }

  return (
    <StyledBox>
      <Typography variant="h4" gutterBottom>
        Job Aggregation Dashboard
      </Typography>
      <Button onClick={toggleDrawer(true)} startIcon={<FilterListIcon />} sx={{ mb: 2 }}>
        Open Filters
      </Button>
      <FiltersDrawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        filters={localFilters}
        setFilters={handleFilterChange}
        dateRange={localDateRange}
        updateDateRange={handleDateRangeChange}
        categories={categories}
        applyFilters={applyFilters}
      />
      <ChartComponent data={filteredData} />
    </StyledBox>
  );
};

export default JobAggregationTab;